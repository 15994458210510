<template>
  <div class="trip-plan-point"
    v-on:click="toLink(`${$locale.base}${params.link}`)">
    <div class="trip-plan-point__wrapper">
      <Column v-if="!$mobile">
        <Row class="trip-plan-point__top"
          justify="between">
          <div class="trip-plan-point__image-holder grid-md-hlf">
            <img v-if="!!params.images"
              class="trip-plan-point__image"
              :src="params.images[0]"
              alt/>
          </div>
          <Column class="trip-plan-point__info grid-md-hlf"
            justify="between">
            <div>
              <div class="trip-plan-point__number-container">
                <p class="trip-plan-point__number">{{params.index}}</p>
              </div>
              <Divider class="trip-plan-point__divider"/>
              <router-link :to="`${$locale.base}${params.link}`"
                class="trip-plan-point__title">
                <h5>{{params.title}}</h5>
              </router-link>
            </div>
            <Rating class="trip-plan-point__rating"
              :params="{id: params.id,
              type: params.entity,
              rating: params.rating,
              currentUserRating: params.currentUserRating}"/>
          </Column>
        </Row>
        <div v-if="params.annotation"
          class="trip-plan-point__annotation">
          <p class="trip-plan-point__annotation-text html-insert"
            v-html="params.annotation.replace(/&lt;[^>]+>/g, '')"></p>
        </div>
        <transition name="fade">
          <Row class="trip-plan-point__full-info"
            justify="between"
            v-if="show">
            <Column class="grid-md-hlf grid-lg-3">
              <div v-for="item in array1"
                :key="item.id"
                class="trip-plan-point__full-info-block">
                <Column>
                  <div class="trip-plan-point__full-info-title">{{item.key}}</div>
                  <div class="trip-plan-point__full-info-value">{{item.value}}</div>
                </Column>
              </div>
            </Column>
            <Column class="grid-md-hlf grid-lg-3">
              <div v-for="item in array2"
                :key="item.id"
                class="trip-plan-point__full-info-block">
                <Column>
                  <div class="trip-plan-point__full-info-title">{{item.key}}</div>
                  <div class="trip-plan-point__full-info-value">{{item.value}}</div>
                </Column>
              </div>
            </Column>
          </Row>
        </transition>
        <div v-if="params.additions.length > 0">
          <span class="trip-plan-point__toggle"
            v-on:click="toggle">{{ $t('service.learn_more') }}
          </span>
        </div>
      </Column>
      <Column v-if="$mobile">
        <router-link :to="params.link"
          class="trip-plan-point__title">
          <h5>{{params.index}}. {{params.title}}</h5>
        </router-link>
        <Rating class="trip-plan-point__rating"
          :params="{id: params.id,
            type: params.entity,
            rating: params.rating,
            currentUserRating: params.currentUserRating}"/>
        <div class="trip-plan-point__image-holder">
          <img v-if="!!params.images"
            class="trip-plan-point__image"
            :src="params.images[0]"
            alt/>
        </div>
        <div v-if="params.annotation"
          class="trip-plan-point__annotation">
          <p class="trip-plan-point__annotation-text">{{params.annotation}}</p>
        </div>
        <transition name="fade">
          <div class="trip-plan-point__full-info"
            v-if="show">
            <div v-for="item in params.additions"
              :key="item.id"
              class="trip-plan-point__full-info-block">
              <Column>
                <div class="trip-plan-point__full-info-title">{{item.key}}</div>
                <div class="trip-plan-point__full-info-value">{{item.value}}</div>
              </Column>
            </div>
          </div>
        </transition>
        <div v-if="params.additions && params.additions.length > 0">
          <span class="trip-plan-point__toggle"
            v-on:click.stop.prevent="toggle">{{ $t('service.learn_more') }}
          </span>
        </div>
      </Column>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TripPlanPoint',
  props: {
    params: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      show: false,
      array1: [],
      array2: [],
    };
  },
  created() {
    this.params.additions.forEach((item, index) => {
      if (index % 2 === 0) {
        this.array1 = [...this.array1, item];
      } else {
        this.array2 = [...this.array2, item];
      }
    });
  },
  methods: {
    toggle(e) {
      e.preventDefault();
      this.show = !this.show;
      e.stopPropagation();
    },
    toLink(link) {
      this.$router.push(link);
    },
  },
};
</script>
